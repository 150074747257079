import React from "react";
import { motion } from "framer-motion";
import Img0 from "../../img/0.png";
import Img1 from "../../img/1.png";
import Img2 from "../../img/2.png";
import Img3 from "../../img/3.png";
import Img4 from "../../img/4.png";
import Img5 from "../../img/5.png";
import Img6 from "../../img/6.png";
import Img7 from "../../img/7.png";
import Img8 from "../../img/8.png";
import Img9 from "../../img/9.png";
import Img11 from "../../img/11.png";
import Img13 from "../../img/13.jpg";
import Img14 from "../../img/14.jpg";
import Img15 from "../../img/15.jpg";
import Img16 from "../../img/16.jpg";
import Img17 from "../../img/17.jpg";
import Img18 from "../../img/18.jpg";
import Img19 from "../../img/19.jpg";
import Img20 from "../../img/20.jpg";
import Img21 from "../../img/21.jpg";
import Img22 from "../../img/22.jpg";
import Img23 from "../../img/23.jpg";
import Img24 from "../../img/24.jpg";
import Img25 from "../../img/25.jpg";
import Img26 from "../../img/26.jpg";
import Img27 from "../../img/27.jpg";
import Img28 from "../../img/28.jpg";
import Img30 from "../../img/30.jpg";
import Img31 from "../../img/31.jpg";
import Img32 from "../../img/32.jpg";
import Img33 from "../../img/33.jpg";
import Img34 from "../../img/34.jpg";
import Img35 from "../../img/35.jpg";
import Img36 from "../../img/36.jpg";
import Img37 from "../../img/37.jpg";
import Img38 from "../../img/38.jpg";
const Gallery = () => {
  const gallery1 = [
    {
      img: Img11,
      alt: "Excavator",
    },
    {
      img: Img2,
      alt: "Excavator",
    },
    {
      img: Img1,
      alt: "Workers in construction site",
    },
    {
      img: Img3,
      alt: "Sand pumping from river",
    },
    {
      img: Img4,
      alt: "Dredging Machine",
    },
    {
      img: Img5,
      alt: "Sand Dredging Machine",
    },
    {
      img: Img6,
      alt: "Dredging Machine",
    },
    {
      img: Img7,
      alt: "Dredging Machine",
    },
    {
      img: Img8,
      alt: "Excavator",
    },
    {
      img: Img9,
      alt: "Worker's Helmet",
    },
  ];
  const gallery2 = [
    {
      img: Img13,
      alt: "Wheel Loader",
    },
    {
      img: Img14,
      alt: "Soil Compactor",
    },
    {
      img: Img15,
      alt: "Dump Truck",
    },
    {
      img: Img16,
      alt: "Loding Dreger",
    },
    {
      img: Img18,
      alt: "Loding Dreger",
    },
    {
      img: Img17,
      alt: "Loding Dreger",
    },
    {
      img: Img19,
      alt: "Unloding Dreger",
    },
    {
      img: Img20,
      alt: "Unloding Dreger",
    },
    {
      img: Img21,
      alt: "Unloding Dreger",
    },
    {
      img: Img22,
      alt: "Unloding Dreger",
    },
    {
      img: Img23,
      alt: "Unloding Dreger",
    },
    {
      img: Img24,
      alt: "Unloding Dreger",
    },
    {
      img: Img25,
      alt: "Unloding Dreger",
    },
    {
      img: Img26,
      alt: "Unloding Process of a Bulkhead Carrier Sand",
    },
    {
      img: Img28,
      alt: "Sand Filling work at Dhaka zone",
    },
    {
      img: Img27,
      alt: "Site view of our Excuted Project",
    },

    {
      img: Img30,
      alt: "Unloading Dreger",
    },
    {
      img: Img31,
      alt: "Bulkhead Carrier",
    },
    {
      img: Img32,
      alt: "Unloading Dreger",
    },
    {
      img: Img33,
      alt: "Bulkhead Carrier",
    },
    {
      img: Img34,
      alt: "Bulkhead Carrier",
    },

    {
      img: Img35,
      alt: "Bulkhead Carrier",
    },
    {
      img: Img36,
      alt: "Excavator",
    },
    {
      img: Img37,
      alt: "Long Boom Excavator",
    },
    {
      img: Img38,
      alt: "Wheel Loader",
    },
  ];
  return (
    <div
      className={
        "flex flex-col justify-around gap-3 lg:flex-wrap lg:flex-row px-2 bg-gradient-to-tr from-[#2f5ea3] from-10% via-[#3581F0] via-30% to-[#c95e01] to-90%"
      }
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <motion.div
        className="flex flex-col gap-3 w-full md:w-max"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <p className="w-full text-5xl md:text-7xl p-2 text-white m-auto">
          Gallery
        </p>
        <img src={Img0} alt="Excavator" />
      </motion.div>

      {gallery1.map((el, i) => (
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <img key={i} src={el.img} alt={el.alt} />
        </motion.div>
      ))}

      {gallery2.map((el, i) => (
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <img
            key={i}
            className={`${
              i === 0
                ? "w-96"
                : i === 1
                ? "w-80"
                : i === 2
                ? "w-80"
                : i === 3
                ? "w-96"
                : i === 4
                ? "w-80"
                : i === 5
                ? "w-96"
                : i === 6
                ? "w-96"
                : i === 7
                ? "w-96"
                : i === 8
                ? "w-96"
                : i === 9
                ? "w-80"
                : i === 10
                ? "w-80"
                : i === 11
                ? "w-96"
                : i === 12
                ? "w-96"
                : i === 13
                ? "w-96"
                : i === 14
                ? "w-96"
                : i === 15
                ? "w-96"
                : i === 16
                ? "w-80"
                : i === 17
                ? "w-80"
                : i === 18
                ? "w-96"
                : i === 19
                ? "w-96"
                : i === 20
                ? "w-80"
                : i === 21
                ? "w-96"
                : i === 22
                ? "w-96"
                : i === 22
                ? "w-80"
                : i === 23
                ? "w-96"
                : i === 24
                ? "w-96"
                : "w-full"
            }`}
            src={el.img}
            alt={el.alt}
          />
        </motion.div>
      ))}
    </div>
  );
};

export default Gallery;
