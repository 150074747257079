import React from "react";
import video from "../video/Construction.mp4";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
const FirstSectionService = () => {
  return (
    <motion.div
      initial={{ opacity: 0.5 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="h-[300px] md:h-[400px]"
    >
      <video
        className="object-cover w-full h-full opacity-90 brightness-50 grayscale-[50%] md:grayscale-[30%]"
        autoPlay
        loop
        muted
        src={video}
      />

      <div className="w-5/6 md:w-2/3 lg:w-1/2 mt-28 md:mt-32 h-max top-0 z-10 pl-6 text-white absolute border-l-4 border-[#2f5ea3] ml-6 md:ml-20 border-solid">
        <div className="pt-8 pb-6 flex flex-col gap-5">
          <p className="text-3xl md:text-6xl">SERVICE</p>

          <Link
            to="/contact"
            className="font-lato w-max px-6 py-3 text-lg text-white rounded-lg bg-[#2f5ea3] hover:scale-110 transition-transform duration-300"
          >
            Contact Us
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default FirstSectionService;
