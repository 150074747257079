import React from "react";
import { Link } from "react-router-dom";

const UpperFooter = () => {
  return (
    <div className="flex flex-col gap-5 items-center mx-auto text-center bg-gradient-to-tr from-[#2f5ea3] from-10% via-[#3581F0] via-30% to-[#c95e01] to-90% py-6 w-full">
      <p className="w-2/3 py-3 mx-auto font-Lato text-4xl lg:text-6xl text-[#FFFFFF]">
        READY TO START YOUR NEW PROJECT?
      </p>
      <p className="text-[64px] text-[#FFFFFF]"></p>

      <Link
        className="px-6 py-3 text-lg font-semibold text-[#2f5ea3] rounded-full bg-white hover:text-white hover:bg-[#2f5ea3] hover:scale-110 transition-transform duration-300"
        to="/contact"
      >
        GET IN TOUCH
      </Link>
    </div>
  );
};

export default UpperFooter;
