import React from "react";
import Img from "../img/about_1.png";
import Img1 from "../img/Working.png";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { Slide } from "react-reveal";
import { Link } from "react-router-dom";
import UpperFooter from "../components/UpperFooter";
import EsteemedClients from "../components/EsteemedClients";

const About = () => {
  const rules = [
    "HONESTY",
    "INTEGRITY",
    "TIMELY",
    "TRUSTWORTHY",
    "SAFE",
    "RELIABLE",
  ];
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About - Sumon Enterprise</title>
        <meta
          name="description"
          content="We take immense pride in our involvement in transformative projects, such as the esteemed S. Alam Project in Bashkhali, Chattagram, under the esteemed guidance of Spectra Engineer's LTD, as well as our collaboration on the housing project development with the renowned Bashundhara Group in Dhaka."
        />
        <meta
          name="keyword"
          content="S. Alam Project in Bashkhali, Chattagram,  Spectra Engineer's LTD , Basundhara LTD. "
        />
      </Helmet>
      <div className="flex flex-col gap-5 md:gap-16">
        <div className="">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <img
              className="brightness-50 w-screen h-[300px] md:h-[400px]"
              src={Img}
              alt="Workers"
            />
          </motion.div>

          <div className="w-5/6 md:w-2/3 lg:w-1/2 mt-28 md:mt-32 h-max top-0 z-10 pl-6 text-white absolute border-l-4 border-[#2f5ea3] ml-6 md:ml-20 border-solid">
            <div className="pt-8 pb-6 flex flex-col gap-5">
              <p className="text-3xl md:text-6xl">ABOUT US</p>
              <Link
                to="/our-work"
                className="font-lato w-max px-6 py-3 text-lg text-white rounded-lg bg-[#2f5ea3] hover:scale-110 transition-transform duration-300"
              >
                See our work
              </Link>
            </div>
          </div>
        </div>

        <div className="flex flex-col text-lg text-[#373737] gap-3 pr-10 font-lato  border-r-[#2f5ea3] border-r-4 border-solid w-5/6 md:w-2/3 lg:w-1/2 mx-auto">
          <p className="py-3 text-[30px] md:text-[40px] font-bold text-transparent bg-clip-text bg-gradient-to-b from-[#2f5ea3] to-black">
            Message from Owner
          </p>
          <p>
            Our journey in the realm of land development and construction
            equipment supply has been marked by significant milestones and
            unwavering dedication.
          </p>

          <p>
            We take immense pride in our involvement in transformative projects,
            such as the esteemed S. Alam Project in Bashkhali, Chattagram, under
            the esteemed guidance of Spectra Engineer's LTD, as well as our
            collaboration on the housing project development with the renowned
            Bashundhara Group in Dhaka.
          </p>

          <p>
            At M/S Sumon Enterprise, we firmly believe that a successful project
            hinges on the right blend of industry expertise, robust
            partnerships, and a proven track record. Our ability to seamlessly
            engage a wide array of heavy equipment and cutting-edge machinery
            enables us to tackle large-scale endeavors with precision and
            efficiency.
          </p>

          <div>
            <p className="">Sincerely,</p>
            <p className="font-bold text-[#2c2c2c]">
              Md Somirul Alam Sarker Owner,
              <br />
              M/S Sumon Enterprise
            </p>
          </div>
        </div>

        <div className="w-full mx-auto bg-gradient-to-tr from-[#2f5ea3] from-10% via-[#3581F0] via-30% to-[#c95e01] to-90%">
          <div className="flex gap-3 w-5/6 m-auto">
            <div className="py-2 lg:pl-32 lg:pr-20 flex flex-col gap-3 my-auto font-lato text-white">
              <p className="text-[30px] md:text-[40px] font-bold">OUR VALUES</p>
              <img
                className="md:hidden block w-60 rounded-md m-auto"
                alt="Our values and constrution site"
                src={Img1}
              />
              <p className="md:text-lg text-md">
                At M/S Sumon Enterprise, our core values are excellence,
                integrity, and innovation. We prioritize client satisfaction,
                safety, and sustainability while fostering collaboration and
                continuous improvement. These principles drive us to deliver
                top-quality land development and equipment supply services.
              </p>
            </div>
            <Slide right duration={1500}>
              <img
                className="hidden md:block md:w-96"
                alt="Our values and constrution site"
                src={Img1}
              />
            </Slide>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-3 md:w-2/3 lg:w-1/2 m-auto ">
          {rules.map((item, index) => (
            <motion.div
              initial={{ transform: "rotateX(0deg)" }}
              whileInView={{ transform: "rotateX(360deg)" }}
              transition={{ duration: 1 }}
            >
              <div
                key={index}
                className="bg-gradient-to-r from-[#2f5ea3] to-[#c95e01] hover:from-[#c95e01] hover:to-[#2f5ea3] rounded-lg m-auto w-32 h-14 md:w-40 md:h-20 items-center flex bg-[#942242] text-[#FFFFFF]"
              >
                <p className="m-auto">{item}</p>
              </div>
            </motion.div>
          ))}
        </div>

        <EsteemedClients />

        <UpperFooter />
      </div>
    </>
  );
};

export default About;
