import React from "react";
import Video from "../video/Footer.mp4";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import BusinessIcon from "@mui/icons-material/Business";
import { Link } from "react-router-dom";
const Footer = () => {
  const iconSize = 16;

  return (
    <div className="w-full absolute z-10 flex flex-col gap-3 justify-center items-center text-white">
      <video
        className="h-[550px] lg:h-[535px] object-cover w-full brightness-50 grayscale-[50%] md:grayscale-[30%]"
        autoPlay
        loop
        muted
        src={Video}
      />
      <div className="absolute w-full h-full px-2 top-0 z-10 flex flex-col gap-6 justify-center items-center text-white">
        <div className="text-center flex flex-col gap-3">
          <p className="text-[30px] md:text-[40px] font-bold">
            Want to Build the Future Together?
          </p>

          <p className="text-[25px] md:text-[30px] font-bold">Let's Connect</p>
        </div>

        <Link
          to="/contact"
          className="font-lato w-max px-10 py-3 text-xl text-white rounded-full bg-[#2f5ea3] hover:scale-110 transition-transform duration-300"
        >
          Contact Us
        </Link>
        <div className="flex flex-row flex-wrap lg:grid lg:grid-cols-3 gap-5 md:gap-3 justify-evenly">
          <p className="m-auto w-full md:w-3/4 text-center">
            <a
              href="https://www.google.com/maps/search/?q=Ka-08, Road-Ein- Uddin Monshi Sarok, Jogonnathpur, Bashundhara, Vatara, Dhaka 1229"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BusinessIcon style={{ fontSize: `${iconSize}px` }} /> Ka-08,
              Road-Ein- Uddin Monshi Sarok, Jogonnathpur, Bashundhara, Vatara,
              Dhaka 1229
            </a>
          </p>

          <div className="flex flex-col gap-2 m-auto">
            <a href="mailto:mdsamirulalam9911@gmail.com" className="m-auto">
              <EmailIcon style={{ fontSize: `${iconSize}px` }} />{" "}
              <span>mdsamirulalam9911@gmail.com</span>
            </a>
            <a href="mailto:sumonenterprise987@gmail.com" className="m-auto">
              <EmailIcon style={{ fontSize: `${iconSize}px` }} />{" "}
              <span>sumonenterprise987@gmail.com</span>
            </a>
          </div>

          <a href="tel:+8801798559911" className="m-auto">
            <PhoneIcon style={{ fontSize: `${iconSize}px` }} /> +880 1798-559911
          </a>
        </div>

        <div className="flex gap-6">
          <a
            href="https://www.facebook.com/people/MS-Sumon-Enterprise/61551682001280/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="bg-transparent w-max h-max rounded-full p-2 transition-transform transform hover:scale-120">
              <FacebookIcon />
            </button>
          </a>

          <a href="tel:+8801798559911">
            <button className="bg-transparent w-max h-max rounded-full p-2 transition-transform transform hover:scale-120">
              <WhatsAppIcon />
            </button>
          </a>
        </div>

        <p className="">
          Done by{" "}
          <a
            className="underline underline-offset-6"
            href="https://www.kreatech.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            kreatech.ca
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
