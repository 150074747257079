import React from "react";
import { Helmet } from "react-helmet";
import EsteemedClients from "../components/EsteemedClients";
import LoadUnloadDredging from "./LoadUnloadDredging";
import FirstSectionService from "./FirstSectionService";
const Service = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Service - Sumon Enterprise</title>
        <meta
          name="description"
          content="Load . Unload . Dredging , Land Development Services , Equipment Rental Services , Construction Consulting , Demolition Services"
        />
        <meta
          name="keyword"
          content=" Land Development Services , Equipment Rental Services , Construction Consulting , Demolition Services"
        />
      </Helmet>
      <div className="flex flex-col gap-5 md:gap-16">
        <FirstSectionService />
        <LoadUnloadDredging />

        <EsteemedClients />
      </div>
    </>
  );
};

export default Service;
