import React from "react";
import Construction from "../img/Construction.png";
import LandService from "../img/LandService.png";
import EquipRental from "../img/Equip_Rental_Service.png";
import Demolition from "../img/Demolition.png";
import Loading from "../img/service_loading.png";
import Unloading from "../img/service_unloading.png";
import Bulkhead from "../img/service_bulkhead.png";
import { motion } from "framer-motion";
const LoadUnloadDredging = () => {
  const services = [
    {
      img: Loading,
      alt: "Loading",
      title: "Loading Dredger:",
      desciption: [
        "Loading Dredger Expertise: We specializes in the loading and operation of dredger, utilizing industry-leading equipment and techniques to efficiently handle various materials and products.",
        "Material Transport: We excel in the movement and loading of materials, offering reliable services for industries such as construction, agriculture, and logistics. Our proficiency ensures seamless transportation of goods.",
        "Safety and Precision: We places a strong emphasis on safety and precision. Our skilled operators are trained to handle dredger with utmost care and accuracy, making us a trusted choice for critical material handling tasks.",
      ],
    },
    {
      img: Unloading,
      alt: "Unloading",
      title: "Unloading Dredger:",
      desciption: [
        "Efficient Unloading Expertise: Your go-to expert for unloading dredger, offering efficient and safe services to facilitate the unloading of various materials and goods.",
        "Seamless Material Discharge: We excel in the unloading of materials, ensuring a smooth and efficient discharge process. Our skilled operators use state-of-the-art equipment to handle various goods with precision.",
        "Customized Solutions: We understand that different industries have unique unloading needs. We provide customized unloading dragger services tailored to the specific requirements of our clients, improving overall operational efficiency.",
      ],
    },
    {
      img: Bulkhead,
      alt: "Bulkhead Machine",
      title: "Loading Dragger Expertise:",
      desciption: [
        "Expertise in Bulkheads: We specialize in designing, constructing, and maintaining bulkheads, delivering reliable solutions for shoreline and waterway management.",
        "Sturdy and Secure Structures: We engineer bulkheads that are not only robust and sturdy but also designed for long-term durability. These structures provide essential support in controlling erosion, protecting waterfront properties, and maintaining waterway infrastructure.",
        "Custom Design and Construction: Our team works closely with clients to create tailored bulkhead solutions that meet specific project requirements. Whether for residential, commercial, or municipal purposes, we ensure that the design aligns with the needs of our clients.",
        "Maintenance and Repairs: In addition to construction, we offer ongoing maintenance and repair services for bulkheads, extending the life of these structures and safeguarding the surrounding environment.",
      ],
    },
    {
      img: LandService,
      alt: "LandService",
      title: "Land Development Services:",
      desciption: [
        "Site Evaluation and Planning: Assessing land for potential development and creating comprehensive plans.",
        "Grading and Earthwork: Preparing the site by levelling, grading, and shaping the land as needed.",
        "Land Clearing: Removing trees, vegetation, and debris from the construction area.",
        "Drainage Solutions: Implementing effective drainage systems to prevent erosion and flooding.",
      ],
    },
    {
      img: EquipRental,
      alt: "Rental Service",
      title: "Equipment Rental Services:",
      desciption: [
        "Construction Equipment Rentals: Offering a wide range of construction machinery for short-term or long-term rentals.",
        "Operator Services: Providing skilled equipment operators when needed.",
        "Maintenance and Repairs: Ensuring that rented equipment remains in optimal working condition.",
      ],
    },
    {
      img: Construction,
      alt: "Construction",
      title: "Construction Consulting:",
      desciption: [
        "Project Management: Overseeing and coordinating construction projects from inception to completion.",
        "Budgeting and Cost Estimation: Helping clients plan and manage project budgets.",
        "Regulatory Compliance: Ensuring that construction projects adhere to local building codes and regulations.",
        "Quality Assurance: Implementing quality control measures to maintain construction standards.",
      ],
    },
    {
      img: Demolition,
      alt: "Demolition",
      title: "Demolition Services:",
      desciption: [
        "Controlled Demolition: Safely dismantling structures while minimizing environmental impact.",
        "Debris Removal: Clearing and disposing of demolition debris responsibly.",
        "Site Remediation: Preparing land for redevelopment after demolition.",
      ],
    },
  ];
  return (
    <div className="flex flex-col gap-3">
      <p className="mx-auto py-3 text-[30px] md:text-[40px] font-bold text-transparent bg-clip-text bg-gradient-to-b from-[#3581F0] to-black">
        Load . Unload . Dredging
      </p>

      <div className="flex flex-col gap-3">
        {services.map((item, index) => {
          return (
            <motion.div
              key={index}
              className="m-auto w-5/6 lg:w-3/4 flex gap-4"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <img className="md:block hidden" src={item.img} alt={item.alt} />

              <div
                key={index}
                className="md:ml-4 font-Lato flex flex-col gap-3"
              >
                <p className="font-bold text-[22px]">{item.title}</p>
                <img
                  className="block md:hidden w-full m-auto"
                  src={item.img}
                  alt={item.alt}
                />
                {item.desciption.map((point, index) => {
                  return (
                    <ul className="list-disc ml-5 md:ml-10 text-[18px]">
                      <li key={index}>{point}</li>
                    </ul>
                  );
                })}
              </div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

export default LoadUnloadDredging;
