import React from "react";
import wC1 from "../../img/wC1.jpg";
import wC2 from "../../img/wC2.jpg";
import wC3 from "../../img/wC3.jpg";
import wC4 from "../../img/wC4.jpg";
const WorkCertificates = () => {
  const images = [
    { image: wC1, alt: "East West Property Development Certificate 2022" },
    {
      image: wC2,
      alt: "Bashundhara Indurtrial Economic Zone Limited Certificate 2021",
    },
    { image: wC3, alt: "East West Property Development Certificate 2021" },
    {
      image: wC4,
      alt: "Bashundhara Indurtrial Economic Zone Limited Certificate 2020",
    },
  ];
  return (
    <div className="flex flex-col gap-3 px-4 mx-auto">
      <p className="m-aito text-[30px] md:text-[40px] text-center font-bold text-transparent bg-clip-text bg-gradient-to-b from-[#3581F0] to-black">
        WORK COMPLETION CERTIFICATES...
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6 m-auto">
        {images.map((item) => {
          return (
            <img
              key={item}
              className="h-[450px] m-auto"
              src={item.image}
              alt={item.alt}
            />
          );
        })}
      </div>
    </div>
  );
};

export default WorkCertificates;
