import React from "react";
import Bashundhara from "../img/Bashundhara.svg";
import EastWest from "../img/EastWest.svg";
import Spectrum from "../img/Spectrum.svg";
import NStraders from "../img/NStraders.png";
import Momin from "../img/Momin.png";
import Shuktara from "../img/Shuktara.png";
import { motion } from "framer-motion";
const EsteemedClients = () => {
  const companies = [
    {
      img: Bashundhara,
      alt: "Bashundara",
      title: "Bashundhara Group",
    },
    {
      img: EastWest,
      alt: "EastWest",
      title: "East West Property Development",
    },
    {
      img: Spectrum,
      alt: "Spectrum",
      title: "Spectra Engineers Limited",
    },
    {
      img: NStraders,
      alt: "N.S TRADERS",
      title: "N.S Taders",
    },
    {
      img: Momin,
      alt: "Momin Enterprise",
      title: "মেসার্স মমিন এন্টারপ্রাইজ",
    },
    {
      img: Shuktara,
      alt: "Shuktara Construction",
      title: "Shuktara Construction",
    },
  ];
  return (
    <div className="flex flex-col gap-3 md:gap-6 justify-center items-center">
      <p className="text-[30px] md:text-[40px] font-bold text-transparent bg-clip-text bg-gradient-to-b from-[#3581F0] to-black">
        OUR ESTEEMED CLIENTS
      </p>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="grid grid-cols-2 md:grid-cols-3 m-auto w-5/6">
          {companies.map((item, index) => {
            return (
              <div key={index} className="mx-auto">
                <img
                  className={`w-max h-20 m-auto ${
                    index === 0 ? "w-52" : "w-max"
                  }`}
                  src={item.img}
                  alt={item.alt}
                />
                <p className="w-5/6 font-lato text-[18px] font-[400] text-center mx-auto p-4">
                  {item.title}
                </p>
              </div>
            );
          })}
        </div>
      </motion.div>
    </div>
  );
};

export default EsteemedClients;
