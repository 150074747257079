import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import video from "../../video/excavator.mp4";
const FirstSectionHome = () => {
  return (
    <motion.div
      initial={{ opacity: 0.5 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="h-[500px]"
    >
      <video
        loop
        autoPlay
        muted
        className="object-cover w-full h-full opacity-90 grayscale-[50%] md:grayscale-[30%] brightness-50"
      >
        <source src={video} />
      </video>

      <div className="w-5/6 md:w-2/3 lg:w-1/2 mt-44 md:mt-32 h-max top-0 z-10 flex flex-col gap-5 text-white absolute ml-6 md:ml-20 border-solid">
        <p className="w-max ml-8 text-lg border-b-4 border-[#2f5ea3] pr-4">
          Building the Future Together.
        </p>
        <p className="whitespace-pre-wrap leading-[3rem] text-3xl md:text-6xl pl-6 border-l-4 border-[#2f5ea3]">
          An Enterprise of Land Development & Equipment Supply
        </p>

        <Link
          to="/our-work"
          className="font-lato w-max px-6 py-3 text-lg text-white rounded-lg bg-[#2f5ea3] hover:scale-110 transition-transform duration-300"
        >
          See our work
        </Link>
      </div>
    </motion.div>
  );
};

export default FirstSectionHome;
