import React from "react";
import { Helmet } from "react-helmet";
import UpperFooter from "../components/UpperFooter";
import WorkOrders from "./components/WorkOrders";
import WorkCertificates from "./components/WorkCertificates";
import FirstSectionWork from "./components/FirstSectionWork";
const Work = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Our Work - Sumon Enterprise</title>
        <meta
          name="description"
          content="We offer an end-to-end client experience that includes seamless communication, budgeting, staffing, on-site organisation, and solid, quality handiwork every time. We excel in commercial projects, encompassing both new construction and repair/restoration endeavors."
        />
        <meta
          name="keyword"
          content="client experience, construction, repair, restoration... "
        />
      </Helmet>
      <div className="flex flex-col gap-5 md:gap-16">
        <FirstSectionWork />

        <WorkOrders />

        <WorkCertificates />

        <UpperFooter />
      </div>
    </>
  );
};

export default Work;
