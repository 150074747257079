import React from "react";
import emailjs from "@emailjs/browser";
import Img from "../img/Contact.png";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import EmailIcon from "@mui/icons-material/Email";
import BusinessIcon from "@mui/icons-material/Business";
const Contact = () => {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_89o7wd4",
        "template_ek5rv3l",
        e.target,
        "3IGbf-ZCICggQ1b_6"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  const iconSize = 16;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact - Sumon Enterprise</title>
        <meta
          name="description"
          content="We offer an end-to-end client experience that includes seamless communication, budgeting, staffing, on-site organisation, and solid, quality handiwork every time. We excel in commercial projects, encompassing both new construction and repair/restoration endeavors."
        />
        <meta
          name="keyword"
          content="client experience, construction, repair, restoration... "
        />
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <div className="flex flex-col gap-5 md:gap-16 mb-5">
        <motion.div
          className="mt-20"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <img
            className="mx-auto w-[90%] h-[250px] md:h-[350px]"
            src={Img}
            alt="Contact us"
          />

          {/* </motion.div> */}

          <div className="w-1/2 md:w-2/3 lg:w-1/2 mt-36 md:mt-44 h-max top-0 z-10 pl-10 text-white absolute ml-6 md:ml-20">
            <p className="text-3xl md:text-6xl">
              Request for a Free Quotation.
            </p>
          </div>
        </motion.div>

        <div className="flex flex-col gap-3 mx-auto w-2/3 mb-5">
          <p className="py-3 text-[30px] md:text-[40px] font-Lato font-medium text-[#2f5ea3]">
            LET'S GET IN TOUCH!
          </p>
          <div className="flex flex-col md:grid md:grid-cols-2 gap-3 m-auto ">
            <form className="w-5/6 flex flex-col gap-10" onSubmit={sendEmail}>
              <div class="relative z-0 w-full group">
                <input
                  type="text"
                  name="name"
                  class="block py-3 px-1 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                  Full Name
                </label>
              </div>
              <div class="relative z-0 w-full group">
                <input
                  type="email"
                  name="email"
                  class="block py-3 px-1 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                  Email
                </label>
              </div>

              <div class="relative z-0 w-full group">
                <input
                  type="text"
                  name="message"
                  class="block py-3 px-1 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                  Message
                </label>
              </div>

              <div className="m-auto">
                <button
                  type="submit"
                  className="bg-gradient-to-tr from-[#2f5ea3] from-10% via-[#3581F0] via-30% to-[#c95e01] to-90% text-white font-medium px-10 py-3 md:px-15 md:py-4 lg:px-20 lg:py-5 rounded-full hover:scale-110 transition-transform duration-300"
                >
                  Contact Us
                </button>
              </div>
            </form>

            <div className="grid grid-rows-2 gap-3 font-Lato font-semibold text-[18px]">
              <div className="flex flex-col gap-3">
                <p className="text-[30px]">Contact</p>
                <p className="flex flex-col gap-2">
                  <a
                    href="mailto:sumonenterprise987@gmail.com"
                    className="text-blue-800"
                  >
                    <EmailIcon style={{ fontSize: `${iconSize}px` }} />{" "}
                    <span className="hover:underline hover:underline-offset-2">
                      sumonenterprise987@gmail.com
                    </span>
                  </a>
                </p>
              </div>
              <div className="flex flex-col gap-3">
                <p className="text-[30px]">Address</p>
                <p className="font-normal">
                  <a
                    href="https://www.google.com/maps/search/?q=Ka-08, Road-Ein- Uddin Monshi Sarok, Jogonnathpur, Bashundhara, Vatara, Dhaka 1229"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BusinessIcon style={{ fontSize: `${iconSize}px` }} />{" "}
                    Ka-08, Road-Ein- Uddin Monshi Sarok, Jogonnathpur,
                    Bashundhara, Vatara, Dhaka 1229
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
