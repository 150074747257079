import React, { useState } from "react";
import w1 from "../../img/w1.jpg";
import w2 from "../../img/w2.jpg";
import w3 from "../../img/w3.jpg";
import w4 from "../../img/w4.jpg";
import w5 from "../../img/w5.jpg";
import w6 from "../../img/w6.jpg";
import w7 from "../../img/w7.jpg";
import w8 from "../../img/w8.jpg";
import w9 from "../../img/w9.jpg";
import w10 from "../../img/w10.jpg";
import w11 from "../../img/w11.jpg";
import w12 from "../../img/w12.jpg";
import w13 from "../../img/w13.jpg";
import w14 from "../../img/w14.jpg";
import w15 from "../../img/w15.jpg";
import w16 from "../../img/w16.jpg";
import w17 from "../../img/w17.jpg";
import w18 from "../../img/w18.jpg";
import ArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
const WorkOrders = () => {
  const images = [
    {
      image: w1,
      alt: "Work Order from Bashundhara Indurtrial Economic Zone Limited",
    },
    { image: w2, alt: "Work Order from East West Property Development" },
    {
      image: w3,
      alt: "Work Order from Bashundhara Indurtrial Economic Zone Limited",
    },
    { image: w4, alt: "Work Order from East West Property Development" },
    {
      image: w5,
      alt: "Work Order from Bashundhara Indurtrial Economic Zone Limited",
    },
    { image: w6, alt: "Work Order from East West Property Development" },
    { image: w7, alt: "Work Order from Spectra Engineers Limited" },
    { image: w8, alt: "Work Order from Spectra Engineers Limited" },
    { image: w9, alt: "Work Order from East West Property Development" },
    { image: w10, alt: "Work Order from East West Property Development" },
    { image: w11, alt: "Work Order from East West Property Development" },
    { image: w12, alt: "Work Order from East West Property Development" },
    { image: w13, alt: "Work Order from East West Property Development" },
    { image: w14, alt: "Work Order from East West Property Development" },
    { image: w15, alt: "Work Order from East West Property Development" },
    { image: w16, alt: "Work Order from East West Property Development" },
    { image: w17, alt: "Work Order from East West Property Development" },
    { image: w18, alt: "Work Order from East West Property Development" },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const slidesToShowMedium = 2;
  const slidesToShowSmall = 1;
  const totalSlides = images.length;

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + totalSlides) % totalSlides);
  };

  const getVisibleImagesMedium = () => {
    const visibleImages = [];
    for (let i = 0; i < slidesToShowMedium; i++) {
      visibleImages.push(images[(currentSlide + i) % totalSlides]);
    }
    return visibleImages;
  };

  const getVisibleImagesSmall = () => {
    const visibleImages = [];
    for (let i = 0; i < slidesToShowSmall; i++) {
      visibleImages.push(images[(currentSlide + i) % totalSlides]);
    }
    return visibleImages;
  };
  return (
    <div className="flex flex-col gap-3 w-5/6 mx-auto">
      <p className="m-aito text-[30px] md:text-[40px] text-center font-bold text-transparent bg-clip-text bg-gradient-to-b from-[#3581F0] to-black">
        SOME OF OUR WORK ORDERS...
      </p>
      <div className="hidden lg:flex flex-row flex-wrap gap-6 m-auto">
        <button
          onClick={prevSlide}
          className="p-2 hover:bg-[#3581F0] hover:text-white rounded-full m-auto"
        >
          <ArrowLeftIcon />
        </button>

        {getVisibleImagesMedium().map((item, index) => {
          return (
            <img
              key={item.image}
              className={`h-[450px] m-auto`}
              src={item.image}
              alt={item.alt}
            />
          );
        })}

        <button
          onClick={nextSlide}
          className="p-2 hover:bg-[#3581F0] hover:text-white rounded-full m-auto"
        >
          <ArrowRightIcon />
        </button>
      </div>

      <div className="flex flex-col lg:hidden gap-4 m-auto">
        {getVisibleImagesSmall().map((item, index) => {
          return (
            <img
              key={item.image}
              className={`h-[450px] m-auto`}
              src={item.image}
              alt={item.alt}
            />
          );
        })}
        <div className="m-auto flex gap-6">
          <button
            onClick={prevSlide}
            className="p-2 hover:bg-[#3581F0] hover:text-white rounded-full m-auto"
          >
            <ArrowLeftIcon />
          </button>
          <button
            onClick={nextSlide}
            className="p-2 hover:bg-[#3581F0] hover:text-white rounded-full m-auto"
          >
            <ArrowRightIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default WorkOrders;
