import React from "react";

const SecondSectionHome = () => {
  return (
    <div className="flex flex-col gap-3 pl-10 font-lato  border-l-[#2f5ea3] border-l-4 border-solid w-5/6 md:w-2/3 lg:w-1/2 mx-auto">
      <p className="text-[30px] md:text-[40px] font-bold text-transparent bg-clip-text bg-gradient-to-b from-[#3581F0] to-black">
        Building the Future Together.
      </p>
      <p className="text-lg text-[#373737]">
        We offer an end-to-end client experience that includes seamless
        communication, budgeting, staffing, on-site organisation, and solid,
        quality handiwork every time. We excel in commercial projects,
        encompassing both new construction and repair/restoration endeavors. We
        are adept at seamlessly handling job sites that are occupied and fully
        operational. We work with architects and designers to produce beautiful,
        functional structures.
      </p>
      <p className="font-semibold text-lg text-[#373737]">
        <span className="italic text-[#3581F0] text-[18px]">Call us</span> today
        and bring our project management skills and extensive construction
        experience to your next project.
      </p>
    </div>
  );
};

export default SecondSectionHome;
