import React from "react";
import { Helmet } from "react-helmet";
import Gallery from "./components/Gallery";
import FirstSectionHome from "./components/FirstSectionHome";
import SecondSectionHome from "./components/SecondSectionHome";
const Home = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Sumon Enterprise</title>
        <meta
          name="description"
          content="We offer an end-to-end client experience that includes seamless communication, budgeting, staffing, on-site organisation, and solid, quality handiwork every time. We excel in commercial projects, encompassing both new construction and repair/restoration endeavors."
        />
        <meta
          name="keyword"
          content="Sumon-enterprise, Sumon-ent,sumon-ent,sumon-enterprise,sumon,......"
        />
      </Helmet>
      <div className="flex flex-col gap-5 md:gap-16">
        <FirstSectionHome />

        <SecondSectionHome />

        <Gallery />
      </div>
    </>
  );
};

export default Home;
