import React from "react";
import { Slide } from "react-reveal";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Img from "../../img/Dredger.avif";
import ourWork1 from "../../img/ourWork1.png";
import ourWork2 from "../../img/ourWork2.png";
import ourWork3 from "../../img/ourWork3.png";
import ourWork4 from "../../img/ourWork4.png";
import ourWork5 from "../../img/ourWork5.png";
const FirstSectionWork = () => {
  return (
    <div className="">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <img
          className="brightness-50 w-screen mx-auto h-[300px] md:h-[400px]"
          src={Img}
          alt="Excavator"
        />
      </motion.div>

      <div className="w-5/6 md:w-2/3 lg:w-1/2 mt-28 md:mt-32 h-max top-0 z-10 pl-6 text-white absolute border-l-4 border-[#2f5ea3] ml-6 md:ml-20 border-solid">
        <div className="pt-8 pb-6 flex flex-col gap-5">
          <p className="text-3xl md:text-6xl">OUR WORK</p>

          <Link
            to="/our-work"
            className="font-lato w-max px-6 py-3 text-lg text-white rounded-lg bg-[#2f5ea3] hover:scale-110 transition-transform duration-300"
          >
            See our work
          </Link>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 bg-gradient-to-tr from-[#2f5ea3] from-10% via-[#3581F0] via-30% to-[#c95e01] to-90% px-5 md:px-10 py-2">
        <div className="flex flex-col gap-6 w-full md:w-3/4 ml-auto">
          <Slide left duration={1500}>
            <img src={ourWork1} alt="Roads and Highways" />
            <img src={ourWork2} alt="Lands" />
            <img src={ourWork3} alt="Lands" />
          </Slide>
        </div>
        <div className="flex flex-col gap-6 my-auto w-full md:w-3/4">
          <Slide right duration={1500}>
            <img src={ourWork4} alt="Lands" />
            <img src={ourWork5} alt="Lands" />
          </Slide>
        </div>
      </div>
    </div>
  );
};

export default FirstSectionWork;
