import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Img from "./img/logo_no_bg.png";
import HamburgerButton from "./components/Hamburger";

import "./Navbar.css";
const Navbar = () => {
  const location = useLocation();
  const [active, setActive] = useState(false);
  const handleClick = () => {
    setActive(false);
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };
  return (
    <div className="navbar-container">
      <div className="flex justify-between md:justify-evenly px-2 z-40 fixed top-0 left-0 right-0 bg-white h-16 shadow-md border-b-2">
        <Link to="/" className="flex">
          <img
            className="w-12 h-12 md:w-14 md:h-14 mt-2 md:mt-1"
            src={Img}
            alt="Sumon Enterprise Logo"
          />

          <p className="font-semibold my-auto ml-1 text-xl font-lato">
            M/S SUMON ENTERPRISE
          </p>
        </Link>

        <div className="my-auto hidden md:block">
          <ul className="flex gap-2 lg:gap-5">
            {[
              {
                name: "Home",
                path: "/",
              },
              {
                name: "About",
                path: "/about",
              },
              {
                name: "Service",
                path: "/service",
              },
              {
                name: "Our work",
                path: "/our-work",
              },
              {
                name: "Contact",
                path: "/contact",
              },
            ].map((el, i) => (
              <li
                key={i}
                className={`text-lg lg:text-xl ${
                  location.pathname === el.path
                    ? "text-[#3581F0] border-b-2 border-[#3581F0] border-solid font-semibold cursor-pointer"
                    : "hover:text-[#3581F0] hover:border-b-2 hover:border-[#3581F0] hover:border-solid hover:scale-110 transition-transform duration-300 cursor-pointer"
                }`}
              >
                <Link to={el.path} onClick={handleClick} className="px-2">
                  {el.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="my-auto block md:hidden">
          <HamburgerButton active={menuOpen} onClick={toggleMenu} />
        </div>
      </div>

      <div
        className={`mobile-menu m-auto h-full w-full flex justify-center items-center ${
          menuOpen ? "open" : ""
        }`}
        onClick={closeMenu}
      >
        <ul className="h-1/2 m-auto flex flex-col justify-evenly">
          {[
            {
              name: "Home",
              path: "/",
            },
            {
              name: "About",
              path: "/about",
            },
            {
              name: "Service",
              path: "/service",
            },
            {
              name: "Our work",
              path: "/our-work",
            },
            {
              name: "Contact",
              path: "/contact",
            },
          ].map((el, i) => (
            <li
              key={i}
              className={`font-bold text-2xl m-auto cursor-pointer
                ${
                  location.pathname === el.path
                    ? "text-[#3581F0] border-b-2 border-[#3581F0] border-solid"
                    : "text-white hover:text-[#3581F0] hover:border-b-2 hover:border-[#3581F0] hover:border-solid hover:scale-110"
                }
                  `}
            >
              <Link to={el.path} onClick={handleClick} className="px-2">
                {el.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
